/**
 * Util
 * ================================================== */
@media screen and (max-width: $m) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: ($m+1)) {
  .sp {
    display: none !important;
  }
}

/********************************************

Fade

**********************************************/

.fadein {
  opacity: 0;
  transition: all 1s;

  &.scroll-fade {
    opacity: 1;
  }
}

.fadein-right {
  opacity: 0;
  transition: all 1s;
  transform: translate(50px, 0);

  &.scroll-fade {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.fadein-left {
  opacity: 0;
  transition: all 1s;
  transform: translate(-50px, 0);

  &.scroll-fade {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.fadein-top {
  opacity: 0;

  transition: all 1s;

  transform: translate(0, -50px);

  &.scroll-fade {
    opacity: 1;

    transform: translate(0, 0);
  }
}

.fadein-bottom {
  opacity: 0;
  transition: all 1s;
  transform: translate(0, 50px);

  &.scroll-fade {
    opacity: 1;
    transform: translate(0, 0);
  }
}
