/**
 * Index
 * ================================================== */
.index {
  .wrp {
    min-height: 100vh;
    min-height: 100dvh;
    height: 100vh;
    height: 100dvh;
  }

  .contents {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    h1 {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
    }
  }
}
