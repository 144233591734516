/**
 * Base
 * ================================================== */
html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  scrollbar-gutter: stable;
}

body {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #111;
  text-decoration: none;
}

.inner {
  width: 100%;
  max-width: 1262px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}

.inner-full {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // 最小15px,最大104px
  padding: 0 clamp(0.938rem, -8.238rem + 19.14vw, 6.5rem);
}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.effect-fade-hover {
  transition: opacity 0.5s;

  &:hover {
    opacity: .7;
  }
}
